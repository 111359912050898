import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { Cross1Icon, ExclamationTriangleIcon } from "@radix-ui/react-icons";

import * as React from "react";
import { cva, VariantProps } from "class-variance-authority";

import { cn } from "@/lib/utils";

const errorBannerVariants = cva("bg-red-50", {
  variants: {
    position: {
      relative: "relative",
      absolute: "absolute left-2 right-2 mx-auto w-auto mt-2",
    },
  },
  defaultVariants: {
    position: "relative",
  },
});

type ErrorBannerProps = {
  description?: React.ReactNode;
  title?: string;
  dismissBanner?: () => void;
  className?: string;
};

const ErrorBanner = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & ErrorBannerProps & VariantProps<typeof errorBannerVariants>
>(({ className, position, description, title, dismissBanner, ...props }, ref) => {
  if (!description) return null;

  const titleText = title || "Oops, something went wrong";

  return (
    <Alert
      variant="destructive"
      ref={ref}
      role="alert"
      className={cn(errorBannerVariants({ position }), className)}
      {...props}
    >
      <ExclamationTriangleIcon className="h-4 w-4" />
      <AlertTitle className="font-semibold">{titleText}</AlertTitle>
      <AlertDescription>{description}</AlertDescription>
      {dismissBanner && (
        <div>
          <Cross1Icon className="h-4 w-4 cursor-pointer absolute top-3 right-3" onClick={dismissBanner} />
        </div>
      )}
    </Alert>
  );
});
ErrorBanner.displayName = "ErrorBanner";

export { ErrorBanner };
